html 
{
    position: relative;
    min-height: 100%;
}

body { 
    padding-top: 5rem;
    margin-bottom: 60px;
}

h3 {
    color: #5b89b3;
    font-size: 1.5rem;
}

a {
    color: #497cab;
    text-decoration-color: #497cab;
    text-decoration: underline;
}